import ChatFeed from './ChatFeed';
import Footer from './Footer';

const ChatRoom = () => {
  return (
    <>
      <ChatFeed />
      <Footer />
    </>
  );
};

export default ChatRoom;
